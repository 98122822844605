import React, { useRef, useState } from 'react'
import { START_TRIAL_CLICKED } from '../../helpers/events'
import useAmplitude from '../../hooks/useAmplitude'
import useOperatingSystem from '../../hooks/useOperatingSystem'

export default function Header() {
  const ref = useRef()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const { isMac } = useOperatingSystem()
  const { logEvent } = useAmplitude()

  const onStartTrialClicked = () => {
    logEvent(START_TRIAL_CLICKED)
    window.location = 'https://download-notifire.herokuapp.com/'
  }

  const onSubscribe = async () => {
    if (!ref.current.reportValidity()) {
      return
    }

    try {
      await fetch(
        'https://us-central1-nichemates-75f78.cloudfunctions.net/addToList',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            listId: '36fd124f-814e-478c-b7d7-48f7e778f9ef',
            email: ref.current.value,
          }),
        },
      )
      setSuccess(true)
    } catch (err) {
      setError(true)
    }
  }

  const onFocus = () => {
    setSuccess(false)
    setError(false)
  }

  const getForm = () => {
    if (isMac()) {
      return (
        <div className="flex flex-col w-full lg:w-1/2">
          <button
            onClick={onStartTrialClicked}
            className="px-6 py-3 text-base font-normal leading-normal text-center text-white no-underline align-middle bg-yellow-500 cursor-pointer select-none md:text-xl hover:bg-yellow-600 hover:text-white rounded-xl">
            Start 7 Day Free Trial
          </button>
          <p className="mt-2 text-sm font-bold text-gray-400">
            No credit card required!
          </p>
        </div>
      )
    } else {
      return (
        <div className="box-border flex flex-col items-center justify-center w-full mt-5 mt-12 mb-2 text-left text-gray-800 lg:w-3/4 lg:flex-row md:text-center">
          <input
            required
            id="form"
            ref={ref}
            onFocus={onFocus}
            className="w-full px-6 py-3 mb-1 mr-1 bg-gray-200 lg:self-end lg:flex-grow lg:mt-0 lg:mb-0 rounded-xl"
            placeholder="E-Mail"
            type="email"
            autoComplete="email"
          />
          <div className="flex flex-col w-full lg:w-1/2">
            <button
              onClick={onSubscribe}
              className="px-6 py-3 text-base font-normal leading-normal text-center text-white no-underline align-middle bg-yellow-500 cursor-pointer select-none md:text-xl hover:bg-yellow-600 hover:text-white rounded-xl">
              Get Early Access
            </button>
          </div>
        </div>
      )
    }
  }

  return (
    <section className="w-full bg-white">
      <div className="box-border flex-grow w-full px-10 pt-8 pb-12 mx-auto leading-6 text-gray-800 lg:pt-16 lg:pb-20 max-w-7xl">
        <div className="relative w-full px-0 mb-12 leading-6 text-left md:text-center lg:flex-grow-0 lg:flex-shrink-0">
          <h1 className="box-border mt-0 mb-4 text-3xl font-black text-gray-800 md:text-4xl lg:text-5xl xl:text-6xl">
            All your notifications in&nbsp;
            <p className="font-black text-yellow-500">one place 🙏</p>
          </h1>
          <p className="box-border mt-0 text-base font-normal text-gray-800 opacity-90 lg:text-xl xl:text-2xl">
            Switching context is draining... and unproductive
          </p>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="box-border flex flex-col items-center justify-center w-full mt-12 mb-2 text-left text-gray-800 lg:w-3/4 lg:flex-row md:text-center">
              {getForm()}
            </div>
            <p
              className={`w-full lg:w-3/4 text-sm text-right mb-0 ${
                error ? 'text-red-500' : 'text-green-500'
              } b ${error || success ? 'opacity-100' : 'opacity-0'}`}>
              {!error && (
                <>
                  <span>Cheers Mate.&nbsp;</span>
                  <span role="img" aria-label="wave">
                    🎉
                  </span>
                </>
              )}
              {error && (
                <>
                  <span>Something went wrong.&nbsp;</span>
                  <span role="img" aria-label="wave">
                    😵
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="relative flex-grow-0 flex-shrink-0 w-full max-w-5xl p-0 mx-auto mt-12 overflow-hidden shadow-2xl lg:flex-grow-0 lg:flex-shrink-0 rounded-xl">
          <img
            src="https://ph-files.imgix.net/cd6aac14-53c9-47db-b18f-b7e5571eef71.png?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=1280&h=720&fm=pjpg&fit=max&dpr=2"
            className="box-border leading-6 text-gray-800 align-middle border-none"
            alt=""
          />
        </div>
      </div>
    </section>
  )
}
