import React from 'react'

export default function Why() {
  return (
    <section id="why" className="w-full px-8 pt-20 pb-16 bg-white xl:px-0">
      <div className="flex flex-col max-w-6xl mx-auto">
        <h3 className="text-4xl font-extrabold leading-none sm:text-5xl md:text-6xl lg:leading-7 text-gray-800">"Not a side project... &nbsp;a necessity"</h3>
        <div className="grid grid-cols-6 gap-5 mt-8 md:grid-cols-12 md:gap-10 lg:mt-12">
          <div className="col-span-6 text-base font-normal text-gray-700 lg:leading-8 xl:leading-8 md:text-xl">
            <p><b>🙋‍♂️ You're probably like us:</b> got a full time job, some side hustles and maybe do a dash of freelancing.</p><br />
            <p>🥵 We struggled to keep up with the <i>stupid</i> amount of notifications we get every day. And we're no slackers... we're obsessed with productivity!</p><br />
            <p>🛠️ It took us a while, but we made a better way to deal with notifications and concentrate on the task of the moment. <i>Maybe it will work for you too?</i></p><br />
          </div>
          <div className="col-span-6 text-base font-normal text-gray-700 lg:leading-8 xl:leading-9 md:text-xl">
            
            <p>✅<span className="font-bold"> Work on What You're Working on -</span> <i></i>don't get distracted by everything else you've got going on. One thing at a time.</p><br />
            <p>✅<span className="font-bold"> More Time <i>Working</i> -</span> <i></i>don't spend your time jumping between apps... do it from one window!</p><br />
            <p>Check the right notifications, at the right time, <i>on your own terms.</i></p><br />
          </div>
        </div>
      </div>
    </section>
  )
}
