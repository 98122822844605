import React from 'react'
import { Switch, Route } from 'react-router-dom'

import useAmplitude from './hooks/useAmplitude'
import useTrackPageView from './hooks/useTrackPageView'
import Header from './components/Header'
import Why from './components/Why'
import Features from './components/Features'
import Integrations from './components/Integrations'
import Pricing from './components/Pricing'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import Navigation from './components/Navigation'
import useOperatingSystem from './hooks/useOperatingSystem'

function App() {
  const { logEvent } = useAmplitude({ key: 'bda244d9b34abffb35fb9767aeb289b6' })
  const { isMac } = useOperatingSystem()
  useTrackPageView({ logEvent })

  return (
    <Switch>
      <Route path="/terms">
        <Navigation />
        <Terms />
        <Footer />
      </Route>
      <Route path="/privacy">
        <Navigation />
        <Privacy />
        <Footer />
      </Route>
      <Route path="/">
        <Navigation />
        <Header />
        <Why />
        <Features />
        <Integrations />
        {isMac() && <Pricing />}
        <Testimonials />
        <Footer />
      </Route>
    </Switch>
  )
}

export default App
