import React from 'react'

export default function Features() {
  return (
    <section id="features" className="py-20 bg-white">
      <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
        <div className="relative">
          <h2 className="w-full box-border text-center m-0 text-4xl font-extrabold leading-none sm:text-5xl md:text-6xl lg:leading-7 box-border m-0 text-gray-800">How's it work?</h2>
          <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">Group your notifications by company, project or even by feature. Got lots of side hustles? Minimize distracting notifications so you can focus on what matters, when it matters.</p>
        </div>
        <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
          <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
            <img className="shadow-xl rounded-xl" src="https://user-images.githubusercontent.com/6383038/116722133-54794680-a9de-11eb-92ed-c446bbec41f5.png" alt="Spaces" />
          </div>
          <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
            <p className="mb-2 text-sm font-semibold leading-none text-left text-yellow-500 uppercase">Clear context separation</p>
            <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">Create Workspaces</h3>
            <p className="mt-5 text-lg text-gray-700 text md:text-left">Your notifications will be automatically sorted into these "Spaces".</p>
          </div>
        </div>
        <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
          <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
            <img className="shadow-xl rounded-xl" src="https://user-images.githubusercontent.com/6383038/116722128-53481980-a9de-11eb-9351-419410f4cc37.png" alt="Apps" />
          </div>
          <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
            <p className="mb-2 text-sm font-semibold leading-none text-left text-yellow-500 uppercase">Let us know what services you use</p>
            <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">Add your apps</h3>
            <p className="mt-5 text-lg text-gray-700 text md:text-left">So Notifire can collect and sort your notifications.</p>
          </div>
        </div>
        <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
          <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
            <img className="shadow-xl rounded-xl" src="https://ph-files.imgix.net/cd6aac14-53c9-47db-b18f-b7e5571eef71.png?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=1280&h=720&fm=pjpg&fit=max&dpr=2" alt="Notifications" />
          </div>
          <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
            <p className="mb-2 text-sm font-semibold leading-none text-left text-yellow-500 uppercase">No more context switching</p>
            <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">See <span className="font-bold">only</span> what matters, <span className="font-bold">right now</span></h3>
            <p className="mt-5 text-lg text-gray-700 text md:text-left">Your notifications <span className="font-bold">automatically</span> sorted into their spaces.</p>
          </div>
        </div>
        <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
          <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
            <img className="shadow-xl rounded-xl" src="https://user-images.githubusercontent.com/6383038/116739296-ca3bdd00-a9f3-11eb-91db-7c43344038ae.png" alt="Notifications" />
          </div>
          <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
            <p className="mb-2 text-sm font-semibold leading-none text-left text-yellow-500 uppercase">Notification inbox zero in 5 seconds</p>
            <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">One click to jump between Notifications</h3>
            <p className="mt-5 text-lg text-gray-700 text md:text-left">Do it now... or come back when you're ready to tick it off.</p>
          </div>
        </div>
        {/* <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
          <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
            <img className="shadow-xl rounded-xl" src="https://user-images.githubusercontent.com/6383038/116739296-ca3bdd00-a9f3-11eb-91db-7c43344038ae.png" alt="Notifications" />
          </div>
          <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
            <p className="mb-2 text-sm font-semibold leading-none text-left text-yellow-500 uppercase">Notification inbox 0 in 5 seconds</p>
            <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">Jump between Notifications with one Click</h3>
            <p className="mt-5 text-lg text-gray-700 text md:text-left">
              Come back to it if you're not ready tick it off.
            </p>
          </div>
        </div> */}
    </div>
  </section>
  )
}
