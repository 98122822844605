const _amplitude = require('amplitude-js')
const { AMPLITUDE_KEY } = require('../../helpers/config')

const amplitude = _amplitude.getInstance()
amplitude.init(AMPLITUDE_KEY, null)

export default function UseAmplitude() {
  const logEvent = (name, data) => {
    amplitude.logEvent(name, data)
  }

  const setUserId = (userId) => {
    amplitude.setUserId(userId)
  }

  const setUserProperties = (userProperties) => {
    amplitude.setUserProperties(userProperties)
  }

  return { logEvent, setUserId, setUserProperties }
}
