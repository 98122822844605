import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { PAGE_VIEWED } from '../../helpers/events'

export default function useTrackPageView({ logEvent }) {
  const location = useLocation()

  const queryDict = (search) => {
    if (!search) {
      return undefined
    }
    const dict = {}
    search.substr(1).split('&').forEach((item) => {
      const items = item.split('=')
      dict[items[0]] = decodeURIComponent(items[1])
    })
    return dict
  }

  useEffect(() => {
    logEvent(PAGE_VIEWED, {
      path: location.pathname,
      hash: location.hash,
      query: queryDict(location.search),
    })
  }, [location, logEvent])
}
