import React from 'react'
import { PRICING_PLAN_CLICKED } from '../../helpers/events'
import useAmplitude from '../../hooks/useAmplitude'

export default function Pricing() {
  const { logEvent } = useAmplitude()
  const onClick = (plan) => {
    logEvent(PRICING_PLAN_CLICKED, { plan })
    window.location = 'https://download-notifire.herokuapp.com/'
  }

  return (
    <section
      id="pricing"
      className="box-border py-8 leading-7 text-gray-900 bg-white border-0 border-gray-200 border-solid sm:py-12 md:py-16 lg:py-24">
      <div className="box-border max-w-6xl px-4 pb-12 mx-auto border-solid sm:px-6 md:px-6 lg:px-4">
        <div className="flex flex-col items-center leading-7 text-center text-gray-900">
          <h2 className="box-border m-0 text-4xl font-extrabold leading-none text-gray-800 sm:text-5xl md:text-6xl lg:leading-7">
            Pricing Options
          </h2>
          <p className="box-border mt-4 text-2xl leading-normal text-gray-900 border-solid">
            We've got a plan for companies of any size
          </p>
        </div>
        <div className="grid max-w-md mx-auto mt-6 overflow-hidden leading-7 text-gray-900 border border-b-4 border-gray-300 border-yellow-500 rounded-lg md:max-w-lg lg:max-w-none sm:mt-10 lg:grid-cols-3">
          <div className="box-border px-4 py-8 mb-6 text-center bg-white border-solid lg:mb-0 sm:px-4 sm:py-8 md:px-8 md:py-12 lg:px-10">
            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-gray-800 border-0 border-solid sm:text-3xl md:text-4xl">
              Monthly
            </h3>
            <p className="mt-3 leading-7 text-gray-900 border-0 border-solid">
              Access to all the pro features for one month. Free 7-Day Trial.
              Cancel Anytime
            </p>
            <div className="flex items-center justify-center mt-6 leading-7 text-gray-900 border-0 border-solid sm:mt-8">
              <p className="box-border m-0 text-6xl font-semibold leading-normal text-center border-0 border-gray-200">
                $9.99
              </p>
              <p className="box-border my-0 ml-4 mr-0 text-xs text-left border-0 border-gray-200">
                per month
              </p>
            </div>
            <button
              className="inline-flex items-center justify-center w-full py-3 mt-6 font-sans text-sm leading-none text-center text-yellow-500 no-underline bg-transparent border border-b-2 border-yellow-500 rounded-lg cursor-pointer hover:bg-yellow-500 hover:border-yellow-500 hover:text-white sm:text-base sm:mt-8 md:text-lg"
              onClick={() => onClick('monthly')}>
              Get started
            </button>
          </div>
          <div className="box-border px-4 py-8 mb-6 text-center bg-gray-100 border border-gray-300 border-solid lg:mb-0 sm:px-4 sm:py-8 md:px-8 md:py-12 lg:px-10">
            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-gray-800 border-0 border-solid sm:text-3xl md:text-4xl">
              Yearly
            </h3>
            <p className="mt-3 leading-7 text-gray-900 border-0 border-solid">
              Access to all the pro features for one year. Free 7-Day Trial.
              Cancel Anytime
            </p>
            <div className="flex items-center justify-center mt-6 leading-7 text-gray-900 border-0 border-solid sm:mt-8">
              <p className="box-border m-0 text-6xl font-semibold leading-normal text-center border-0 border-gray-200">
                $49.99
              </p>
              <p className="box-border my-0 ml-4 mr-0 text-xs text-left border-0 border-gray-200">
                per year
              </p>
            </div>
            <button
              className="inline-flex items-center justify-center w-full py-3 mt-6 font-sans text-sm leading-none text-center text-white no-underline bg-yellow-500 border-b-4 border-yellow-600 rounded-lg cursor-pointer hover:text-white sm:text-base sm:mt-8 md:text-lg"
              onClick={() => onClick('yearly')}>
              Get started
            </button>
          </div>
          <div className="box-border px-4 py-8 text-center bg-white border-solid sm:px-4 sm:py-8 md:px-8 md:py-12 lg:px-10">
            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-gray-800 border-0 border-solid sm:text-3xl md:text-4xl">
              Lifetime
            </h3>
            <p className="mt-3 leading-7 text-gray-900 border-0 border-solid">
              Subscription Fatigue? We got you covered with our lifetime plan
            </p>
            <div className="flex items-center justify-center mt-6 leading-7 text-gray-900 border-0 border-solid sm:mt-8">
              <p className="box-border m-0 text-6xl font-semibold leading-normal text-center border-0 border-gray-200">
                $199.99
              </p>
              <p className="box-border my-0 ml-4 mr-0 text-xs text-center border-0 border-gray-200">
                one time payment
              </p>
            </div>
            <button
              className="inline-flex items-center justify-center w-full py-3 mt-6 font-sans text-sm leading-none text-center text-yellow-500 no-underline bg-transparent border border-b-2 border-yellow-500 rounded-lg cursor-pointer hover:bg-yellow-500 hover:border-yellow-500 hover:text-white sm:text-base sm:mt-8 md:text-lg"
              onClick={() => onClick('lifetime')}>
              Get started
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
