import React from 'react'

export default function Testimonials() {
  return (
    <section className="relative block pt-20 pb-24 overflow-hidden text-left bg-white">
      <div className="w-full px-20 mx-auto text-left md:px-10 max-w-7xl xl:px-16">
        <div className="box-border flex flex-col flex-wrap justify-center -mx-4">
          <div className="relative w-full mb-12 leading-6 text-left xl:flex-grow-0 xl:flex-shrink-0">
            <h2 className="box-border w-full m-0 text-4xl font-extrabold leading-none text-center text-gray-800 sm:text-5xl md:text-6xl lg:leading-7">
              Testimonials
            </h2>
            <p className="box-border mt-4 text-2xl leading-normal text-center text-gray-800 border-solid">
              We use Notifire every day. We may be slightly biased...
            </p>
          </div>
        </div>
        <div className="box-border grid flex-wrap justify-center gap-10 -mx-4 text-center md:grid-cols-2 lg:gap-16 lg:justify-start lg:text-left">
          <div className="flex col-span-1">
            <div className="relative flex-shrink-0 w-20 h-20 text-left">
              <img
                src="https://cdn.devdojo.com/tails/avatars/039.jpg"
                className="w-20 h-20 rounded-xl"
                alt=""
              />
            </div>
            <div className="relative px-4 mb-16 leading-6 text-left">
              <div className="box-border text-lg font-medium text-gray-600">
                I love this.
              </div>
              <div className="box-border mt-5 text-sm font-semibold text-yellow-700 uppercase">
                Matthew Phelps
              </div>
              <div
                className="box-border text-left text-gray-700"
                style={{ quotes: 'auto ' }}>
                Niche Mates LLC
              </div>
            </div>
          </div>
          <div className="flex col-span-1">
            <div className="relative flex-shrink-0 w-20 h-20 text-left">
              <img
                src="https://cdn.devdojo.com/tails/avatars/087.jpg"
                className="w-20 h-20 rounded-xl"
                alt=""
              />
            </div>
            <div className="relative px-4 mb-16 leading-6 text-left">
              <div className="box-border text-lg font-medium text-gray-600">
                I don't know these people.
              </div>
              <div className="box-border mt-5 text-sm font-semibold text-yellow-700 uppercase">
                Tasha Smith
              </div>
              <div
                className="box-border text-left text-gray-700"
                style={{ quotes: 'auto ' }}>
                Another Company Name
              </div>
            </div>
          </div>

          <div className="flex col-span-1">
            <div className="relative flex-shrink-0 w-20 h-20 text-left">
              <img
                src="https://cdn.devdojo.com/tails/avatars/094.jpg"
                className="w-20 h-20 rounded-xl"
                alt=""
              />
            </div>
            <div className="relative px-4 mb-5 leading-6 text-left">
              <div className="box-border text-lg font-medium text-gray-600">
                Orange is a nice color.
              </div>
              <div className="box-border mt-5 text-sm font-semibold text-yellow-700 uppercase">
                Stefan Wirth
              </div>
              <div
                className="box-border text-left text-gray-700"
                style={{ quotes: 'auto ' }}>
                Niche Mates LLC
              </div>
            </div>
          </div>

          <div className="flex col-span-1">
            <div className="relative flex-shrink-0 w-20 h-20 text-left">
              <img
                src="https://cdn.devdojo.com/tails/avatars/099.jpg"
                className="w-20 h-20 rounded-xl"
                alt=""
              />
            </div>
            <div className="relative px-4 mb-5 leading-6 text-left">
              <div className="box-border text-lg font-medium text-gray-600">
                What the guy before me said times 2
              </div>
              <div className="box-border mt-5 text-sm font-semibold text-yellow-700 uppercase">
                Rudi Seelig
              </div>
              <div
                className="box-border text-left text-gray-700"
                style={{ quotes: 'auto ' }}>
                Niche Mates LLC
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
