export default function useOperatingSystem() {
  const isMac = () => {
    if (navigator.userAgentData) {
      return navigator.userAgentData.platform === 'macOS'
    }

    return navigator.platform.toLowerCase().indexOf('mac') !== -1
  }

  return {
    isMac,
  }
}
