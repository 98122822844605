/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'

export default function Subpage({
  heading,
  contents,
}) {
  return (
    <>
      <h1 className="font-bold text-3xl lg:text-4xl text-gray-900 w-full lg:w-4/5 text-center m-8">{heading}</h1>
      {contents.map((element) => (
        <div id={element.id} key={element.id} className="bg-white w-10/12 mb-4 rounded shadow py-6 px-8">
          {element.headline && (
            <h2
              className="text-lg lg:text-xl font-bold text-left text-yellow-700 mb-8"
              dangerouslySetInnerHTML={{ __html: element.headline }}
            />
          )}
          <p
            className="text-sm text-left text-gray-900"
            dangerouslySetInnerHTML={{ __html: element.text }}
          />
        </div>
      ))}
      <div className="mb-6" />
    </>
  )
}

Subpage.propTypes = {
  heading: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
}
